import React from 'react'
import Banner from '../../components/Banner/Banner'
import './about.css'
import { motion } from 'framer-motion';
import bannerImg2 from '../../images/about-us.png'

const About = () => {
  return (
    <motion.div 
    initial={{opacity: 0}} 
    animate={{opacity: 1}} 
    exit={{opacity: 0}} 
    className="container about_us" 
    id="about"
  >
    <h2>About Us</h2>
    <Banner 
      comp="home" 
      title="The Mission of White Cabs" 
      description="White Cabs is committed to providing exceptional customer service in Lethbridge. With clean, well-maintained, smoke-free vehicles, we deliver cost-effective transportation solutions, including airport services, online booking options, and tailored KMs schemes for your travel needs." 
      bannerImg={bannerImg2} 
    />
  
    <h2>Features of White Cabs in Lethbridge</h2>
    <div className="timeline">
      <div className="cont left">
        <div className="text_box">
          <p>We enhance response times and passenger comfort by offering faster, accurate, and silent dispatch services in Lethbridge, including airport transfers and local travel.</p>
          <span className="left_arrow"></span>
        </div>
      </div>
      <div className="cont right">
        <div className="text_box">
          <p>Our professional drivers, supported by an advanced information system, ensure reliable service for airport pickups and drop-offs in Lethbridge.</p>
          <span className="right_arrow"></span>
        </div>
      </div>
      <div className="cont left">
        <div className="text_box">
          <p>We provide passengers with useful tools such as route maps and fare estimates, simplifying booking online or planning trips to and from Lethbridge Airport.</p>
          <span className="left_arrow"></span>
        </div>
      </div>
      <div className="cont right">
        <div className="text_box">
          <p>Our secure payment system allows customers to process credit and debit cards conveniently. Whether using our KMs scheme or booking online, your payments are safe with White Cabs.</p>
          <span className="right_arrow"></span>
        </div>
      </div>
    </div>
  </motion.div>
  
  )
}

export default About