import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Faq from '../../components/Faq/Faq'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/wheelchairTaxi.jpg'
import { motion } from 'framer-motion';

const wheelchairTaxi = () => {
  const points = [
    { text: "Travel for vaccinations and medical appointments." },
    { text: "Specialized treatment transportation." },
    { text: "Day programs and rehabilitation visits." },
    { text: "Family and friend outings." },
    { text: "Airport transfers, including Lethbridge to Edmonton Airport." },
    { text: "Weekend special trips and tours." },
  ];

  const points1 = [
    { text: "White Cabs drivers and passengers are required to wear masks." },
    { text: "All wheelchair taxis are sanitized before every ride." },
    { text: "No extra charges; affordable and flat-rate wheelchair taxi service." },
    { text: "Cheap, reliable wheelchair-accessible taxi service in Lethbridge." },
    { text: "Available vehicles include SUVs, sedans, and tempos." },
    { text: "Free luggage services available." },
  ];

  const wheelchairService = [
    {
      title: "Safe and Secure Journey",
      desc: "White Cabs has a team of well-trained drivers dedicated to providing safe, comfortable, and reliable wheelchair-accessible taxi services. Our clean, accessible cabs cater to all residents, including those with special needs, in Lethbridge and surrounding areas. With familiarity in locations like Lethbridge , medical facilities, and popular spots, our drivers ensure smooth transportation for medical appointments, shopping, and dining. White Cabs also supports injured patients requiring reliable transportation.",
    },
    {
      title: "Professional and Well-Trained Drivers",
      desc: "With over 5 years of experience in wheelchair and special needs transportation, our drivers and customer support are trained for a safe, friendly service. Drivers wear masks and uniforms, carry legal documents, and maintain a professional appearance to ensure a safe, pleasant experience for every passenger.",
    },
    {
      title: "24/7 Wheelchair Taxi Service",
      desc: "White Cabs offers wheelchair-accessible taxi services in Lethbridge, available 24/7 with flat rates for airport transfers, medical visits, and more. We prioritize safety and convenience for all our customers, with tailored support for emergencies and personalized rides for handicapped passengers. Our service is designed to ensure comfort and enjoyment for all clients, including tourists and residents.",
    },
  ];

  const faq = [
    {
      title: "Can I hire a wheelchair-accessible taxi in Canada?",
      desc: "Yes, White Cabs offers wheelchair-accessible taxis across Canada, including in Lethbridge, St. Albert, Parkland, and Morinville.",
    },
    {
      title: "Is a 24-hour taxi available for handicapped passengers in Canada?",
      desc: "Yes, our wheelchair-accessible taxi service is available 24/7, allowing you to book any time from any location. Our drivers ensure a safe and comfortable ride in our wheelchair-accessible vans.",
    },
    {
      title: "Are White Cabs sanitized for handicapped passengers?",
      desc: "Yes, White Cabs provides sanitized wheelchair vans for all passengers. Our drivers clean and sanitize the vehicles daily, and options include sedans, SUVs, and tempos to suit your needs.",
    },
    {
      title: "Can I hire a flat-rate taxi in Canada?",
      desc: "Yes, White Cabs offers affordable flat-rate taxi services for various towns and cities. You can book our taxi day or night with genuine rates and no hidden fees.",
    },
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="wheelchair container"
    >
      <Banner 
        comp="home" 
        title="Accessible Wheelchair Taxi Services" 
        description="White Cabs provides accessible, flat-rate transportation in Lethbridge for individuals with wheelchairs, scooters, and other mobility aids. Our door-to-door medical transportation service is available 7 days a week, featuring ramps and secure fittings for all weather conditions. Rely on White Cabs to get to your destination safely and comfortably." 
        bannerImg={bannerImg} 
      />
      <Benefits 
        title="White Cabs Wheelchair Taxi Services" 
        points={points}  
      />
      <Services 
        title="Our On-Demand Services" 
        data={wheelchairService} 
      />
      <Benefits 
        title="Why Choose White Cabs" 
        points={points1}  
      />
      <Faq 
        title="Frequently Asked Questions" 
        data={faq} 
      />
    </motion.div>
  );
};


export default wheelchairTaxi