import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Faq from '../../components/Faq/Faq'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/banner5.jpg'
import './onDemand.css'
import { motion } from 'framer-motion';

const points = [
  {
    text: "Lethbridge Taxi Service"
  },
  {
    text: "24/7 Availability for Airport Transfers"
  },
  {
    text: "Affordable and Transparent Pricing"
  },
  {
    text: "Clean, Comfortable, and Sanitized Vehicles"
  },
  {
    text: "Service Across Lethbridge and Surrounding Areas"
  }
]

const demandService = [
  {
    title: "Reliable Taxi Service to Lethbridge Airport",
    desc: "White Cabs offers reliable and timely airport taxi services to and from Lethbridge Airport. Whether you're arriving for business or leisure, our professional drivers ensure you get to your hotel, office, or other destinations quickly and comfortably. Book now by calling or online."
  },
  {
    title: "Affordable Hotel Transfers Across Lethbridge",
    desc: "Need a ride from Lethbridge Airport to your hotel or vice versa? White Cabs offers affordable hotel taxi services for tourists and business travelers. Enjoy prompt and comfortable transfers, with professional drivers assisting with your luggage and ensuring a smooth ride."
  },
]

const faq = [
  {
    title: "What areas does White Cabs service in Lethbridge?",
    desc: "White Cabs provides services throughout Lethbridge and the surrounding regions, including popular locations like the University of Lethbridge, downtown Lethbridge, and beyond."
  },
  {
    title: "How can I book a taxi in Lethbridge?",
    desc: "Booking a taxi in Lethbridge is easy. Simply call or use our online platform for fast and reliable taxi service. Our team is available 24/7 to assist you with your travel needs."
  },
]

const OnDemand = () => {
  return (
    <motion.div 
      initial={{opacity: 0}} 
      animate={{opacity:1}} 
      exit={{opacity:0}} 
      className='demand container'>
      
      <Banner 
        comp="home" 
        title="On-Demand Taxi Services in Lethbridge" 
        description="White Cabs offers on-demand taxi services in Lethbridge, including airport transfers, hotel pickups, and more. Our reliable and affordable taxi service ensures you travel comfortably with flat-rate pricing, sanitized vehicles, and professional drivers." 
        bannerImg={bannerImg}
      />
      
      <Services title="Our On-Demand Services" data={demandService} />
      
      <Benefits title="Coverage Areas in Lethbridge" points={points} />
      
      <Faq title="Frequently Asked Questions" data={faq} />
    </motion.div>
  );
}

export default OnDemand