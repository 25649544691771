import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/airport.jpg'
import { motion } from 'framer-motion';

const points = [
  {
    text: "White Cabs provides a stress-free and memorable travel experience for all passengers in Lethbridge."
  },
  {
    text: "Our high-quality services include airport taxi transfers to popular destinations in and around Lethbridge."
  },
  {
    text: "White Cabs prioritizes punctuality, ensuring passengers arrive at their destinations comfortably and on time."
  },
  {
    text: "Customers can conveniently reserve a taxi for their desired destination through our reliable airport cab service in Lethbridge."
  },
  {
    text: "Our drivers are skilled professionals committed to providing top-notch service to our clients."
  },
  {
    text: "White Cabs' airport taxi services are always available at major transport hubs, ensuring quick and reliable pickups for travelers in Lethbridge."
  }
];

const demandService = [
  {
    title: "Sanitized Airport Taxi Service in Lethbridge",
    desc: "White Cabs prioritizes passenger safety by offering sanitized airport taxi services in Lethbridge. With a focus on providing secure, reliable, and stress-free rides, we maintain affordable fares while ensuring the highest safety standards."
  },
  {
    title: "24/7 Airport Taxi Service with Luxury Fleet and On-Demand Delivery",
    desc: "Our airport taxi service in Lethbridge operates 24/7, featuring punctual and professional drivers who arrive ahead of time. We offer a diverse fleet of vehicles, including luxury taxis, and provide on-demand delivery services. Corporate and personal accounts are available for frequent travelers, catering to passengers arriving at or departing from Lethbridge Airport."
  },
  {
    title: "Safe and Secure Taxi Service",
    desc: "Safety is our top priority. White Cabs ensures clean and disinfected cabs, with drivers following strict hygiene protocols such as wearing masks and gloves. Every cab is sanitized before and after each ride. We maintain social distancing and monitor the health of both passengers and drivers to provide safe and secure transportation. Our airport taxi services are available 24/7 to meet your needs, even during late hours in Lethbridge."
  }
];

const AirportTaxi = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="airportTaxi container"
    >
      <Banner 
        comp="home" 
        title="Reliable Airport Taxi Services in Lethbridge" 
        description="Need a reliable airport taxi service in Lethbridge? Call or book with White Cabs. Our flat-rate taxi service is available for single passengers or groups of any size. Choose from sedans, SUVs, and Tempo vehicles. We also provide taxi services for hotels, offices, personal use, restaurants, and more. Book your airport taxi to any destination, anytime." 
        bannerImg={bannerImg} 
      />
      <Benefits title="Benefits of Booking Airport Taxi Service in Lethbridge" points={points} />
      <Services title="Our Services" data={demandService} />
    </motion.div>
  );
};



export default AirportTaxi