import React from 'react'
import Banner from '../../components/Banner/Banner'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/car3.svg'
import './spruceTaxi.css'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'
import DownloadBanner from '../../components/DownloadBanner/DownloadBanner'

const SpruceTaxi = () => {
  const demandService = [
    {
      title: "Available 24/7",
      desc: "White Cabs, Spruce Grove taxi service, is available around-the-clock to meet your needs. Whether you need an early morning pick-up or a late-night ride, simply call or book through our website, and our driver will arrive promptly. We also offer special discounts throughout the day when you book with us in Spruce Grove."
    },
    {
      title: "Focused on Health and Safety",
      desc: "At White Cabs, we prioritize your safety. Our drivers rigorously clean and sanitize their vehicles to provide a safe ride for Spruce Grove passengers. Additionally, our drivers follow strict hygiene protocols, including regular hand sanitizing and mask-wearing, ensuring a safe environment for every trip."
    },
    {
      title: "Ensuring Your Comfort",
      desc: "Your comfort is our top priority. White Cabs offers a range of vehicles, including sedans, SUVs, and corporate cars, so you can choose the one that suits you best. Every ride with us is tailored to provide the highest level of comfort and satisfaction."
    },
    {
      title: "Friendly and Professional Drivers",
      desc: "Our Spruce Grove taxi drivers are friendly, courteous, and focused on creating a positive experience. Whether you prefer a quiet ride or enjoy chatting about the city, our drivers are always accommodating and ready to provide a seamless and enjoyable journey."
    },
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="sprucetaxi container"
    >
      <Banner 
        comp="home" 
        title="Spruce Grove Taxi" 
        description="White Cabs has proudly served Spruce Grove for over 3 years, offering reliable and affordable taxi service. If you're seeking a safe, comfortable, and punctual ride, call us at +1 (780) 203 8000 to book your Spruce Grove taxi today." 
        bannerImg={bannerImg}
      />
      <div className="applink">
        <DownloadBanner />
      </div>
      <Services title="What Makes White Cabs a Standout Spruce Grove Taxi Service?" data={demandService} />
    </motion.div>
  );
};


export default SpruceTaxi