import React from 'react'
import './downloadBanner.css';
import downloadDark from '../../images/downloadDark.gif'
import downloadLight from '../../images/downloadLight.gif'
import {Link} from 'react-router-dom'
import andriodDownload from '../../images/andriodDownload.png';
import appleDownload from '../../images/appleDownload.png';
import homeDark from '../../images/download-dark.gif';
import homeLight from '../../images/download-light.gif';

import andriodDownloadBtn from '../../images/google-btn.png';
import appleDownloadBtn from '../../images/apple-btn.jpg';
import downloadBanner from '../../images/book-now.png'


const DownloadBanner = ({homeBanner}) => {
  return (
    <div className='banner-container'>
        <div className="download-left">
            <h2>BOOK CAB FROM OUR APP</h2>    
            <ul>
                <li>Book a cab with just a few taps on your smartphone</li>
                <li>Track your cab's location in real-time for added convenience.</li>
                <li>Our cab service is at your service 24/7, wherever you are.</li>
                <li>Make secure payments through the app for a hassle-free experience.</li>
            </ul>

            <h3> Click the button below to download the app from your app store.</h3>
            
            <div className="download-btns">
              
              {/* <Link className="left-btn" to="https://play.google.com/store/apps/details?id=com.whitecabs.passengerapp"> <button> <img src={andriodDownload} alt="andriod" />  DOWNLOAD</button></Link> <Link className="left-btn" to="https://apps.apple.com/gb/app/white-cabs/id1637899683"><button> <img src={appleDownload} alt="apple" /> DOWNLOAD</button></Link> */}
              
              <Link className="left-btn" to="https://play.google.com/store/apps/details?id=com.whitecabs.passengerapp"> <button> <img src={andriodDownloadBtn} alt="andriod" /> </button></Link> <Link className="left-btn" to="https://apps.apple.com/gb/app/white-cabs/id1637899683"><button> <img src={appleDownloadBtn} alt="apple" /></button></Link>


            </div>
            
        </div> 
        <div className="download-right">
            <img className='download-dark-img' src={homeBanner ? homeDark : downloadDark } alt="dark" />
            <img className='download-light-img' src={homeBanner ? homeLight : downloadLight } alt="dark" />
        </div>       
        {/* <img src={downloadBanner } alt="dark" /> */}
    </div>
  )
}

export default DownloadBanner