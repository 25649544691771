import React from 'react'
import Card from '../../components/Card/Card'
import { TiTickOutline } from 'react-icons/ti'
import { FiHelpCircle } from 'react-icons/fi';
import { TbMultiplier2X } from 'react-icons/tb';
import { FaTelegramPlane } from 'react-icons/fa';
import { AiFillLock, AiOutlineBulb } from 'react-icons/ai';
import './home.css'
import Faq from '../../components/Faq/Faq';
import Carousal from '../../components/Carousal/Carousal';
import { Link } from 'react-router-dom';
import bannerImg1 from '../../images/home-banner.png'
import bannerImg2 from '../../images/taxi2.jpg'
import { motion } from 'framer-motion';
import Banner from '../../components/Banner/Banner';
import DownloadBanner from '../../components/DownloadBanner/DownloadBanner';
import RevolvingStrip from '../../components/RevolvingStrip/RevolvingStrip';

const SliderData = [
  {
      title: 'White cabs -  Lethbridge Taxi',
      desp: 'White cabs started 7 years ago as cooperative taxi. White Cabs is a 24/7 cooperative taxi company that prioritizes customer satisfaction.',
      image: bannerImg1,
      button: <Link to="https://whitecabs.webbooker.icabbi.com/"><button>BOOK NOW</button></Link>
  },
  {
    title: 'White cabs - Lethbridge Taxi',
    desp: ' Their fleet is safe, reliable, and well-maintained, and drivers undergo rigorous training to ensure a pleasant and trouble-free trip. ',
    image: "https://cdn.pixabay.com/photo/2023/03/16/13/09/taxi-7856666_960_720.jpg",
    button: <Link to="https://whitecabs.webbooker.icabbi.com/"><button>BOOK NOW</button></Link>
  },
  {
    title: 'White cabs -  Lethbridge Taxi',
    desp: 'They offer special vehicles for disabled individuals, an affordable parcel delivery service, and comply with strict standards of upkeep and cleanliness.',
    image: bannerImg2,
    button: <Link to="https://whitecabs.webbooker.icabbi.com/"><button>BOOK NOW</button></Link>
  }
]

const cards = [
  {
    icon: <TiTickOutline />,
    title: "Expert drivers",
    des: "White Cabs provide dependable and confident service to and from the airport and surrounding areas with drivers who are familiar with all routes in Lethbridge."
  },
  {
    icon: <AiOutlineBulb />,
    title: "Recent technology",
    des: "We also offer one-on-one service through our call center and management team, as well as convenient automated phone, web, and app booking options for our Lethbridge taxi service."
  },
  {
    icon: <FiHelpCircle />,
    title: "Advance help",
    des: "We have many friendly, helpful drivers who are aware of Lethbridge’s culture, history, and attractions and are able to guide you to the best places to go and see."
  },
  {
    icon: <TbMultiplier2X />,
    title: "Multiplicity",
    des: "We provide a variety of taxi services and value our clients. Our drivers are skilled and capable of handling any situation, providing top-notch service. Experience first-class service with White Cabs' flat rate taxis in Lethbridge."
  },
  {
    icon: <FaTelegramPlane />,
    title: "Taxi service for airport",
    des: "White Cabs also offers professional airport taxi services available 24/7. Our airport taxi service provides a wide range of vehicle options to meet your specific needs. Easily schedule your airport pickup with us."
  },
  {
    icon: <AiFillLock />,
    title: "Fast and secure",
    des: "As Lethbridge's premier taxi service, we provide a safe and reliable option for both personal and business travel needs. Our professional drivers ensure a smooth and secure transportation experience from start to finish."
  }
]

const faq = [
  {
    title: "Looking for a Lethbridge Taxi?",
    desc: "White Cabs provide flat-rate taxi services in Lethbridge. We provide quick and dependable service to our passengers because we know the value of time."
  },
  {
    title: "How do you get a taxi in Lethbridge?",
    desc: "To book a Lethbridge taxi, you can make your booking on our website, White Cabs, or give us a call."
  },
  {
    title: "How to hire a cab in Lethbridge?",
    desc: "Travelers can hire cabs in Lethbridge by visiting our website or calling our White Cabs services contact number."
  },
  {
    title: "Why customer trust WhiteCabs pricing?",
    desc: "The price from Lethbridge to Calgary Airport depends on the travel distance. We offer competitive pricing and flat-rate options for your convenience."
  },
  // {
  //   title: "What is taxi fare finder?",
  //   desc: "We understand passengers want transparent fare details. White Cabs offers flat-rate pricing based on local rates, ensuring affordability and reliability."
  // }
]

const Home = () => {
  
  return (
    <motion.div 
    initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}}
    className='home container'>
      <Banner comp="home" title="Lethbridge Taxis at Your Service – Ride with Us Today!" description="Travel with ease and comfort using White Cabs Lethbridge! We offer flat-rate pricing, fast, and reliable service to ensure you reach your destination on time. No hidden fees, just straightforward, dependable transportation. Book your ride now and experience hassle-free travel!"
      bannerImg={bannerImg1} />
      <DownloadBanner homeBanner='true' />
      <RevolvingStrip />
      <div className='home__services'>
        <h2 className='home__services_title'>Why choose White Cabs <br /> for Lethbridge Taxi Services?</h2>
        <div className="home__services_container">
          {cards.map((card, index) => (
            <Card key={index} card={card} />
          ))}
        </div>
      </div>
      <Faq title="Frequently Asked Questions" data={faq} />
    </motion.div>
  )
}


export default Home