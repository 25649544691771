import React from 'react'
import './serviceCard.css'
import { Link } from 'react-router-dom'
const ServiceCard = ({elm}) => {
    return (
        <div className="service__post">
            <div className="service__post_img">
                <img src={elm.img} alt="post_img" />
            </div>
            <div className="service__post_info">
                {/* <div className="service__post_category_date"> */}
                    {/* <div className="service__post_category">
                        Cabs
                    </div>
                    <div className="service__post_date">
                        16 April
                    </div> */}
                {/* </div> */}
                <div className="service__post_content">
                    <div className="service__post_title">
                        {elm.title}
                    </div>
                    <div className="service__post_desp">
                       {elm.desp}
                    </div>
                </div>
                <div className="service__post_profile_more">
                    <div className="service__post_profile">
                        {/* <div className="service__post_profile_img">
                            <img src="https://cdn.pixabay.com/photo/2017/11/10/05/46/group-2935521_960_720.png" alt="profile_img" />
                        </div> */}
                    </div>
                    <Link to="https://whitecabs.webbooker.icabbi.com/" className="service__post_more">Book Now</Link>
                </div>
            </div>
        </div>
    )
}
export default ServiceCard