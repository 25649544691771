import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import bannerImg from '../../images/car2.svg'
import './edmontonTaxi.css'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'
import DownloadBanner from '../../components/DownloadBanner/DownloadBanner'

const EdmontonTaxi = () => {

  const points = [
    {
      text: "Car rental services available."
    },
    {
      text: "One-way taxi services."
    },
    {
      text: "Round-trip taxi services."
    },
    {
      text: "Convenient door-to-door taxi service."
    },
    {
      text: "Airport transportation services."
    }
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="edmontontaxi container"
    >
      <Banner 
        comp="home" 
        title="Edmonton Taxi Service" 
        description="White Cabs offers affordable and reliable taxi services in Edmonton, including one-way, round-trip, door-to-door, and airport transfers. We also provide specialized Wheelchair Van service for those with mobility challenges. Our well-maintained taxis ensure a comfortable and enjoyable ride for a memorable experience in Edmonton."
        bannerImg={bannerImg} 
      />
      <div className="applink">
        <DownloadBanner />
      </div>
      <Benefits title="Our Taxi Services in Edmonton" points={points} />
    </motion.div>
  );
};


export default EdmontonTaxi