import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Faq from '../../components/Faq/Faq'
import bannerImg from '../../images/banner8.svg'
import { motion } from 'framer-motion';

const TaxiService = () => {
  const faq = [
    {
      title: "Is 24/7 taxi service available across Canada?",
      desc: "Yes, White Cabs offers 24/7 taxi services throughout Canada. You can book our taxis anytime, anywhere. Our drivers arrive promptly, even ahead of schedule to ensure a timely pick-up."
    },
    {
      title: "Which areas does White Cabs service 24/7?",
      desc: "White Cabs operates 24/7 in Lethbridge Taxi, St. Albert, Parkland, Morinville, Sherwood Park, Fort Saskatchewan, Edmonton, and surrounding airports."
    },
    {
      title: "Can I book an airport taxi at any time in Canada?",
      desc: "Yes, passengers can book White Cabs taxis for airport pickups or drop-offs at any time. Our reliable 24/7 service covers all Canadian airports and nearby destinations."
    },
    {
      title: "Why choose White Cabs?",
      desc: "White Cabs offers reliable service, arriving a few minutes early to ensure convenience. We provide budget-friendly, one-way, and round-trip options across Canada. To reserve, call."
    },
    {
      title: "Are sanitized taxis available during lockdown?",
      desc: "Yes, White Cabs offers sanitized vehicles in Edmonton, Morinville, Sherwood Park, Lethbridge Taxi, ensuring safety for all passengers. Our drivers sanitize their taxis daily."
    }
  ];

  const points = [
    { text: "Reliable 24/7 White Cabs taxi service across Canada." },
    { text: "Each cab is sanitized daily for passenger safety." },
    { text: "Affordable, budget-friendly options available." },
    { text: "Honest and punctual drivers." },
    { text: "Choose from a range of taxis: Sedan, SUV, and Tempo." },
    { text: "Professional cleaning of vehicles every day." },
    { text: "Services include Lethbridge Taxi, Edmonton taxi, St. Albert, and more towns." }
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="taxiservice container"
    >
      <Banner 
        comp="home" 
        title="24/7 Reliable Taxi Services" 
        description="White Cabs provides reliable 24/7 taxi services across Canada. Book online at whitecabs.ca or call . Our professional drivers offer safe, sanitized travel anytime, including Lethbridge Taxi. Corporate and personal accounts are available for easy booking and convenience." 
        bannerImg={bannerImg}
      />
      <Benefits title="Why Choose White Cabs?" points={points} />
      <Faq title="Frequently Asked Questions" data={faq} />
    </motion.div>
  );
};


export default TaxiService