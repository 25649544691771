import React, { useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp, AiOutlineMinus, AiOutlineMinusCircle, AiOutlinePlus, AiOutlinePlusCircle } from 'react-icons/ai';
import './faq.css';

const Faq = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="faq__services">
      <h2 className="faq__services_title">Frequently Asked Questions</h2>
      <div className="faq__services_container">
        {data.map((elm, index) => (
          <div
            key={index}
            className={`faq__box ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq__header">
              <h4>{elm.title}</h4>
              <span>
                {activeIndex === index ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
              </span>
            </div>
            <p className="faq__content">{elm.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
