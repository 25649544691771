import React from "react";
import "./revolvingStrip.css";

const RevolvingStrip = () => {
  return (
    <div className="revolving-strip">
      <div className="ticket-strip">
        {Array.from({ length: 10 }).map((_, index) => (
          <div className="ticket" key={index}>
            <div className="ticket-content">
              <div className="ticket-header">
                {/* <span className="icon">🎉</span> */}
                 Use Code: CAB20
              </div>
              <div className="discount">
                Get 
                <span className="percent"> 20%</span> OFF
              </div>
              {/* <div className="ticket-footer">
                <span className="validity">Valid Till: 31st Dec 2024</span>
              </div> */}
            </div>
            <div className="perforation left"></div>
            <div className="perforation right"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RevolvingStrip;
