import React, { useState } from "react";
import "./booking.css"
import { BsFillArrowRightCircleFill,BsFillArrowLeftCircleFill} from "react-icons/bs";
import { motion } from 'framer-motion';
import Banner from "../../components/Banner/Banner";
import bannerImg from '../../images/booking-banner.png'

export const SliderData = [
  {
    text: "To book a taxi online, provide your name, pickup location (address), destination, date and time of the journey, and phone number."
  },
  {
    text: "With the use of modern technology, customers can easily book their taxis from their phones, laptops, or computers with an internet connection."
  },
  {
    text: "White Cabs offers a variety of taxi services, including Lethbridge Taxi, shopping taxi service, Parkland taxi service, Business Taxi Service, and airport taxi service."
  },
  {
    text: "Booking a taxi online is a convenient and easy way to book your ride from the comfort of your own home."
  },
  {
    text: "There are no extra charges for booking online, and customers can also learn more about White Cabs by visiting their website."
  }
];

const Booking = () => {
  const [current, setCurrent] = useState(0);
  const length = SliderData.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(SliderData) || SliderData.length <= 0) {
    return null;
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="booking container"
      >
        <Banner
          comp2="about"
          comp="home"
          title="Download Our App for Convenient Booking"
          description="White Cabs has been serving passengers for over 10 years. With fast, reliable service, you can book your taxi online and learn more about our offerings. Experience the comfort of White Cabs today."
          bannerImg={bannerImg}
        />
        <div className="booking__container">
          <div className="booking__left">
            <h2>Steps to Book a Taxi Online with White Cabs</h2>
            <div className="steps__container">
              <BsFillArrowLeftCircleFill
                className="left-arrow"
                onClick={prevSlide}
                aria-label="Previous Slide"
              />
              {SliderData.map((slide, index) => (
                <div
                  className={index === current ? "slide active" : "slide"}
                  key={index}
                >
                  {index === current && (
                    <div className="ticket">
                      <div className="ticket-header">
                        <h2>Cab Ride Ticket</h2>
                      </div>
                      <div className="ticket-content">
                        {/* <div className="ticket-info">
                          <h3>Call to book a ride</h3> */}
                            {/* <strong>+1 825 982 1212</strong>  */}
                             
                          {/* <p>or use the <strong>online booking system</strong>.</p>
                        </div> */}
                        {/* <div className="ticket-divider"></div> */}
                        <p>{slide.text}</p>
                        <div className="ticket-divider"></div>
                        <div className="ticket-footer">
                          <p>White Cabs - Safe & Reliable Transportation</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <BsFillArrowRightCircleFill
                className="right-arrow"
                onClick={nextSlide}
                aria-label="Next Slide"
              />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Booking;
