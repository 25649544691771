import React from 'react'
import Banner from '../../components/Banner/Banner'
import Benefits from '../../components/Benefits/Benefits'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/download.svg'
import './flatTaxi.css'
import { motion } from 'framer-motion';

const points = [
  {
    text: "Drivers and passengers wear masks for safety."
  },
  {
    text: "All taxis are thoroughly sanitized after each ride."
  },
  {
    text: "No hidden or extra charges for passengers."
  },
  {
    text: "Affordable and consistent flat-rate pricing."
  },
  {
    text: "Convenient service near your location."
  },
  {
    text: "Multiple vehicle options: SUVs, Sedans, and Tempos."
  },
  {
    text: "Free luggage assistance included."
  },
];

const demandService = [
  {
    title: "24/7 Flat Rate Taxi Service with White Cabs",
    desc: "White Cabs offers 24/7 flat-rate taxi service for shopping, business meetings, hotel transfers, or airport pickups across Canada. No matter the time or location, White Cabs is available to provide a safe and reliable ride. You can book our flat-rate taxis anytime, ensuring prompt pickups and minimal waiting time. Experience convenience and consistency during your travels or in transportation emergencies."
  },
  {
    title: "Affordable Airport Transfers at a Flat Rate",
    desc: "Book a flat-rate airport taxi by calling or through our online booking platform. With White Cabs, you can budget your trip with confidence—there are no extra fees unless additional services are requested. This makes it an excellent choice over metered taxis for airport transfers, as you’ll know the cost upfront, making your travel planning smoother and more affordable."
  },
];

const FlatTaxi = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="flat container"
    >
      <Banner 
        comp="home" 
        title="Flat-Rate Taxi Services" 
        description="White Cabs now offers reliable flat-rate taxi services available anytime, anywhere across Canada. Whether for shopping, office commutes, personal use, hotel, airport transfers, medical visits, dining, or tourism, our fleet of Sedan, SUV, and Tempo vehicles is ready for any travel need. We offer flat-rate services for single passengers, pairs, or group travelers. On-demand delivery is also available at a nominal rate."
        bannerImg={bannerImg} 
      />
      <Benefits title="Why Choose White Cabs?" points={points} />
      <Services title="Our Services" data={demandService} />
    </motion.div>
  );
};

export default FlatTaxi