import React from 'react'
import Banner from '../../components/Banner/Banner'
import Services from '../../components/Services/Services'
import bannerImg from '../../images/car.svg'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import DownloadBanner from '../../components/DownloadBanner/DownloadBanner';

const StonyTaxi = () => {
  const demandService = [
    {
      title: "Enjoy Your Journey with White Cabs",
      desc: "Book a taxi with White Cabs for a smooth and enjoyable journey. Focus on what matters while we handle your transportation needs. Our drivers arrive punctually at the designated location, ensuring a relaxing and timely experience. Booking a Stony Plain taxi is fast and simple, available online or by phone, with special discounts for senior citizens."
    },
    {
      title: "Sanitized and Secure Taxis in Stony Plain",
      desc: "Concerned about public transportation due to crowding and hygiene? Stony Plain Taxi offers a clean, sanitized, and secure alternative. Our cabs undergo thorough sanitization after each ride, providing a safe and hassle-free experience during COVID-19 and beyond. Our reliable drivers are dedicated to ensuring a timely and safe journey, letting you travel worry-free."
    }
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      exit={{ opacity: 0 }} 
      className="stonytaxi container"
    >
      <Banner 
        comp="home" 
        title="Stony Plain Taxi" 
        description="Experience efficient, reliable, and comfortable cab services with Stony Plain Taxi for all your transportation needs. Perfect for daily commutes or weekend getaways, our taxi service offers a range of options tailored to your preferences. Choose from luxurious and well-maintained vehicles to make your journey comfortable and enjoyable. Book your taxi on our website today."
        bannerImg={bannerImg}
      />
      <div className="applink">
        <DownloadBanner />
      </div>
      <Services data={demandService} />
    </motion.div>
  );
};


export default StonyTaxi