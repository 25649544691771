import React from 'react'
import bannerImg1 from '../../images/limousine.jpg'
import bannerImg2 from '../../images/hotelTaxi.jpg'
import bannerImg3 from '../../images/banner5.jpg'
import bannerImg4 from '../../images/banner4.jpg'
import bannerImg5 from '../../images/wheelchairTaxi.jpg'
import bannerImg6 from '../../images/airport.jpg'
import bannerImg7 from '../../images/businessTaxi.jpg'
import bannerImg8 from '../../images/bannerImg8.jpg'
import bannerImg9 from '../../images/banner9.svg'
import bannerImg10 from '../../images/banner10.jpg'
import ServiceCard from '../../components/ServiceCard/ServiceCard'
const cards = [
    {
        img: bannerImg1,
        title: 'Luxurious White Limousine Services in Lethbridge',
        desp: 'Looking for a professional white limousine service in Lethbridge, AB? White Cabs offers luxurious, eco-friendly vehicles for special events, holidays, or everyday travel. Our fleet includes vehicles with complimentary WiFi. Book a limo for a flat-rate ride to and from Lethbridge Airport or downtown. Let us make your journey memorable!',
        link: '/service/white-limousine-service'
    },
    {
        img: bannerImg2,
        title: 'Reliable Hotel Taxi Services in Lethbridge',
        desp: "Visiting Lethbridge? White Cabs offers dependable and affordable hotel taxi services in Lethbridge. Call for a reliable ride to and from your hotel, the airport, or shopping areas. Our fleet includes sanitized sedans, SUVs, and tempos, available for business or leisure trips, including airport transfers to Lethbridge Airport.",
        link: '/service/hotel-taxi'
    },
    {
        img: bannerImg3,
        title: 'Convenient On-Demand Delivery in Lethbridge',
        desp: 'White Cabs provides on-demand delivery services in Lethbridge, making it easier to transport items across the city. Call us for courier services or airport taxi pickups. We offer flexible delivery solutions for food, shopping, and more, with services covering Lethbridge and surrounding areas like Coaldale and Fort Macleod.',
        link: '/service/shopping-taxi-service'
    },
    {
        img: bannerImg4,
        title: 'Affordable Shopping Taxi Services in Lethbridge',
        desp: 'Need a taxi for shopping in Lethbridge? Call White Cabs at for flat-rate shopping taxi services across Lethbridge. Our sanitized fleet of sedans, SUVs, and tempos will get you to malls, restaurants, or any shopping destination. We also provide wheelchair-accessible vans for your convenience.',
        link: '/service/shopping-taxi-service'
    },
    {
        img: bannerImg5,
        title: 'Accessible Wheelchair Taxi Services in Lethbridge',
        desp: "White Cabs provides specialized wheelchair taxi services in Lethbridge, offering safe and comfortable rides for passengers with mobility aids. Our fleet includes wheelchair-accessible vehicles available seven days a week for medical appointments, airport transfers, and more.",
        link: '/service/wheelchair-van'
    },
    {
        img: bannerImg6,
        title: 'Reliable Airport Taxi Services in Lethbridge',
        desp: 'Need a taxi to Lethbridge Airport? White Cabs offers reliable airport taxi services with a flat-rate fee. Our fleet includes sedans, SUVs, and tempos, and we cater to both individual passengers and groups. Call us 24/7 for convenient rides to and from the airport.',
        link: '/service/airport-taxi'
    },
    {
        img: bannerImg7,
        title: 'Professional Business Taxi Services in Lethbridge',
        desp: 'White Cabs provides professional business transportation in Lethbridge. Our luxury fleet includes sedans, SUVs, and executive vehicles for corporate accounts, office pickups, and airport transfers. Book a taxi online or by phone, and enjoy a comfortable, timely ride.',
        link: '/service/business-taxi'
    },
    {
        img: bannerImg8,
        title: '24/7 Reliable Taxi Services in Lethbridge',
        desp: "White Cabs operates 24/7 in Lethbridge, offering flat-rate taxi services for all your transportation needs. Our professional drivers ensure a safe and comfortable ride any time of day or night. Call or visit whitecabs.ca to book your ride.",
        link: '/service/24-7-taxi'
    },
    {
        img: bannerImg9,
        title: 'Flat-Rate Taxi Services in Lethbridge',
        desp: 'White Cabs offers flat-rate taxi services in Lethbridge for both short trips and longer journeys, including airport transfers. Our fleet includes sanitized sedans, SUVs, and tempos, all ready for personal, business, or tourist needs. Book your flat-rate ride today!',
        link: '/service/flat-taxi'
    },
    {
        img: bannerImg10,
        title: 'Emergency Car Boosting Services in Lethbridge',
        desp: 'Stranded with a dead battery in Lethbridge? White Cabs provides reliable 24/7 emergency car boosting services. Whether at home, work, or on the road, our team will jumpstart your vehicle quickly and safely. Call for immediate assistance.',
        link: '/service/car-boosting-service'
    },
];

const Service = () => {
  return (
    <div className='blog container'>
        <h2 className='title'>Explore Our Services in Lethbridge</h2>
        <div className="blog__container">
        {
           cards.map((elm, i) => (
            <ServiceCard key={i} elm={elm} />
           )) 
        }
        </div>
    </div>
  );
}

export default Service